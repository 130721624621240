import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'tagUrl', standalone: true})
export class TagUrlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) { }

    transform(tag: string): SafeHtml {
        //return '<a routerLink=[/tags/,'+v+']">'+v+'</a>';

       //return '<a [routerLink]="['/tags', 'JAVA']">JAVA</a>

      //  return '<a [routerLink]="['/pages', 'LEGAL']">Mentions légales</a>

      return '<a href="/tags/'+tag+'">'+tag+'</a>';
    }
}